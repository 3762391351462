import React, { Component } from "react";
import { Button, Header, Input, Menu } from "semantic-ui-react";

export default class SearchCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: props.params.limit || 20,
      collection_name: props.params.collection_name || ""
    };
  }

  onChange = (e, { value }) => {
    let key = e.target.name;
    // Edge case for Select elements
    if (key === undefined || key === null) {
      key = e.target.parentElement.getAttribute("name");
    }
    if (key === undefined || key === null) {
      key = e.target.parentElement.parentElement.getAttribute("name");
    }
    if (key === undefined || key === null) {
      key = e.target.parentElement.parentElement.parentElement.getAttribute(
        "name"
      );
    }
    this.setState({
      [key]: value
    });
  };

  onSubmit = () => {
    this.props.update(this.state);
  };

  render() {
    return (
      <Menu vertical fluid>
        <Menu.Item>
          <Header size="large">Collections</Header>
        </Menu.Item>

        <Menu.Item>
          <Input
            name="collection_name"
            placeholder={"Search by name"}
            value={this.state.collection_name}
            onChange={this.onChange}
          />
        </Menu.Item>

        <Menu.Item>
          <Button onClick={this.onSubmit} color="green">
            Submit
          </Button>
        </Menu.Item>
      </Menu>
    );
  }
}
