import React, { Component } from "react";
import { Label } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

function getTagColor(tag) {
  if (tag.tag_type === "tag") {
    return "grey";
  } else {
    return "black";
  }
}

export default class TagLabel extends Component {
  render() {
    const { tag } = this.props;

    return (
      <Label color={getTagColor(tag)} as={NavLink} exact to={`/tag/${tag.id}`}>
        {tag.name}
      </Label>
    );
  }
}
