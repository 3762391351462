import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./higher-order/ProtectedRouteHOC";
import Login from "./login/Login";
import Authors from "./admin/author/Authors";
import AuthorSingle from "./admin/author/AuthorSingle";
import Collections from "./admin/collection/Collections";
import CollectionSingle from "./admin/collection/CollectionSingle";
import SourceTracker from "./admin/source_tracker/SourceTracker";
import Series from "./admin/series/Series";
import SeriesSingle from "./admin/series/SeriesSingle";
import Tags from "./admin/tag/Tags";
import TagSingle from "./admin/tag/TagSingle";
import AuditPublisherCompany from "./admin/audit/AuditPublisherCompany";
import AuditPublisherLabel from "./admin/audit/AuditPublisherLabel";
import AuditAuthor from "./admin/audit/AuditAuthor";
import SourceScrapeByPublisherCompanyScrape from "./admin/source_scrape/SourceScrapeByPublisherCompanyScrape";
import SourceScrapeByPublisherLabelScrape from "./admin/source_scrape/SourceScrapeByPublisherLabelScrape";
import SourceScrapeByAuthorScrape from "./admin/source_scrape/SourceScrapeByAuthorScrape";
import PublisherCompanies from "./admin/publisher/PublisherCompanies";
import PublisherLabels from "./admin/publisher/PublisherLabels";
import SourceScrapeByPublisherCompany from "./admin/source_scrape/SourceScrapeByPublisherCompany";
import SourceScrapeByPublisherLabel from "./admin/source_scrape/SourceScrapeByPublisherLabel";

const Router = props => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <ProtectedRoute exact path="/" component={SourceTracker} />
    <ProtectedRoute exact path="/source_tracker" component={SourceTracker} />
    <ProtectedRoute
      exact
      path="/audit/publisher_company"
      component={AuditPublisherCompany}
    />
    <ProtectedRoute
      exact
      path="/audit/publisher_label"
      component={AuditPublisherLabel}
    />
    <ProtectedRoute exact path="/audit/author" component={AuditAuthor} />
    <ProtectedRoute exact path="/author" component={Authors} />
    <ProtectedRoute exact path="/author/:id" component={AuthorSingle} />
    <ProtectedRoute exact path="/collection" component={Collections} />
    <ProtectedRoute exact path="/collection/:id" component={CollectionSingle} />
    <ProtectedRoute exact path="/series" component={Series} />
    <ProtectedRoute exact path="/series/:id" component={SeriesSingle} />
    <ProtectedRoute exact path="/tag" component={Tags} />
    <ProtectedRoute exact path="/tag/:id" component={TagSingle} />
    <ProtectedRoute
      exact
      path="/publisher_company"
      component={PublisherCompanies}
    />
    <ProtectedRoute
      exact
      path="/publisher_company/:id"
      component={SourceScrapeByPublisherCompany}
    />
    <ProtectedRoute exact path="/publisher_label" component={PublisherLabels} />
    <ProtectedRoute
      exact
      path="/publisher_label/:id"
      component={SourceScrapeByPublisherLabel}
    />
    <ProtectedRoute
      exact
      path="/source_scrape/publisher_company_scrape/:id"
      component={SourceScrapeByPublisherCompanyScrape}
    />
    <ProtectedRoute
      exact
      path="/source_scrape/publisher_label_scrape/:id"
      component={SourceScrapeByPublisherLabelScrape}
    />
    <ProtectedRoute
      exact
      path="/source_scrape/author_scrape/:id"
      component={SourceScrapeByAuthorScrape}
    />
  </Switch>
);

export { Router };
