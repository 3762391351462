import React, { Component } from "react";
import {
  Breadcrumb,
  Container,
  Divider,
  Grid,
  Header,
  Message
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { getModel } from "../../../util/DataUtil";
import { client } from "../../../index";
import CollectionModel from "../../../component/model/CollectionModel";
import SeriesModel from "../../../component/model/SeriesModel";

export default class CollectionSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      collection: null
    };
  }

  getModel = async () => {
    try {
      const model = await getModel(client.apis.collection.getCollectionById, {
        id: this.props.match.params.id
      });
      this.setState({
        collection: model
      });
    } catch (e) {
      this.setState({
        error: true,
        errorMessage:
          e.response !== undefined && e.response !== null
            ? e.response.obj.message
            : "There was an issue creating"
      });
    }
  };

  async componentDidMount() {
    await this.getModel();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      await this.getModel();
    }
  }

  render() {
    if (this.state.collection !== null) {
      return (
        <Container>
          <Divider hidden />
          <Grid>
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={16}>
                <Breadcrumb size="massive">
                  <Breadcrumb.Section>
                    <NavLink exact to="/collection">
                      Collection
                    </NavLink>
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section active>
                    {this.state.collection.name}
                  </Breadcrumb.Section>
                </Breadcrumb>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden />
            <Grid.Row>
              <Grid.Column width={16}>
                <Header>Overview</Header>

                <CollectionModel collection={this.state.collection} />
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Grid.Column width={16}>
                <Header>Series</Header>

                {this.state.collection.series.map(s => {
                  return <SeriesModel key={s.id} series={s} />;
                })}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    return (
      <Container>
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              {this.state.error ? (
                <Message negative>{this.state.errorMessage}</Message>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
