import React, { Component } from "react";
import { List, Table } from "semantic-ui-react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { SERIES_TYPE_ENUM_TO_TEXT } from "../ModelEnum";

export default class CollectionsTable extends Component {
  render() {
    const { collections } = this.props;

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Name</Table.HeaderCell>
            <Table.HeaderCell>Series</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(collections, ({ id, name, series }) => (
            <Table.Row key={id}>
              <Table.Cell>
                <NavLink exact to={`/collection/${id}`}>
                  {name}
                </NavLink>
              </Table.Cell>

              <Table.Cell>
                <List>
                  {_.map(series, ({ id, name, series_type }) => {
                    return (
                      <List.Item key={id}>
                        <NavLink exact to={`/series/${id}`}>
                          {name} ({SERIES_TYPE_ENUM_TO_TEXT[series_type]})
                        </NavLink>
                      </List.Item>
                    );
                  })}
                </List>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
