import React, { Component } from "react";
import {
  Breadcrumb,
  Container,
  Divider,
  Grid,
  Message
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { getModel } from "../../../util/DataUtil";
import { client } from "../../../index";

export default class SeriesSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      series: null
    };
  }

  getModel = async () => {
    try {
      const model = await getModel(client.apis.series.getSeriesById, {
        id: this.props.match.params.id
      });
      this.setState({
        series: model
      });
    } catch (e) {
      this.setState({
        error: true,
        errorMessage:
          e.response !== undefined && e.response !== null
            ? e.response.obj.message
            : "There was an issue creating"
      });
    }
  };

  async componentDidMount() {
    await this.getModel();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      await this.getModel();
    }
  }

  render() {
    if (this.state.series !== null) {
      return (
        <Container fluid>
          <Divider hidden />
          <Grid padded="horizontally">
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={16}>
                <Breadcrumb size="massive">
                  <Breadcrumb.Section>
                    <NavLink exact to="/series">
                      Series
                    </NavLink>
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section active>
                    {this.state.series.name}
                  </Breadcrumb.Section>
                </Breadcrumb>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden />

            <Grid.Row centered>
              <Grid.Column width={12}>TODO: Generate a View</Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    return (
      <Container>
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              {this.state.error ? (
                <Message negative>{this.state.errorMessage}</Message>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
