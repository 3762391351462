import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { getFromDatum } from "../../../util/DataUtil";

export default class PublisherCompanyLabel extends Component {
  render() {
    const { publisherCompany } = this.props;

    return (
      <Label
        color={"orange"}
        as={NavLink}
        exact
        to={`/publisher/company/${publisherCompany.id}`}
      >
        {getFromDatum(publisherCompany, "name")}
      </Label>
    );
  }
}
