import { client } from "../index";

const queryString = require("query-string");

function getFromDatum(datum, key, defaultReturn = "") {
  return datum !== undefined &&
    datum !== null &&
    key in datum &&
    datum[key] !== undefined &&
    datum[key] !== null
    ? datum[key]
    : defaultReturn;
}

function getFromDatumLocaleDict(datum, key, lang, defaultReturn = "") {
  return datum !== undefined &&
    datum !== null &&
    key in datum &&
    datum[key] !== undefined &&
    datum[key] !== null &&
    lang in datum[key] &&
    datum[key][lang] !== undefined &&
    datum[key][lang] !== null
    ? datum[key][lang]
    : defaultReturn;
}

// Swagger Client
async function getSource(sourceUri) {
  return await getModel(
    client.apis.source.searchSources,
    {
      source_uri: sourceUri
    },
    "source"
  );
}

async function getModel(fn, params, model) {
  try {
    const resp = await fn(params);
    return checkClientResponse(resp, `There was an issue retrieving ${model}`);
  } catch (e) {
    const resp = e.response;

    if (resp.status !== 400) {
      throw e;
    } else {
      return null;
    }
  }
}

async function createModel(fn, params, model) {
  const resp = await fn(params);
  return checkClientResponse(resp, `There was an issue creating ${model}`);
}

function checkClientResponse(response, error) {
  if (response.ok) {
    return response.obj;
  }
  throw new Error(error);
}

function generateQueryString(params) {
  return queryString.stringify(params);
}

function parseQueryString(params) {
  return queryString.parse(params);
}

export {
  getFromDatum,
  getFromDatumLocaleDict,
  getSource,
  getModel,
  createModel,
  checkClientResponse,
  generateQueryString,
  parseQueryString
};
