import React, { Component } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  Table
} from "semantic-ui-react";
import { client } from "../../../index";
import { getFromDatum, getModel } from "../../../util/DataUtil";

class Scraper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series_id_range_start: "",
      series_id_range_end: "",
      disabled: false
    };
  }

  onChange = (e, { value }) => {
    let key = e.target.name;
    // Edge case for Select elements
    if (key === undefined) {
      key = e.target.parentElement.parentElement.getAttribute("name");
    }
    this.setState({
      [key]: value
    });
  };

  onSubmit = () => {
    const { data, onUpdate } = this.props;
    this.setState(
      {
        disabled: true
      },
      async () => {
        let results = null;
        try {
          switch (data.domain) {
            case "bookwalker.jp":
              results = await getModel(
                client.apis.source_tracker.scrapeBookWalker,
                {
                  series_id_range_start: parseInt(
                    this.state.series_id_range_start
                  ),
                  series_id_range_end: parseInt(this.state.series_id_range_end)
                },
                "BookWalker"
              );
              break;
            case "ebookjapan.yahoo.co.jp":
              results = await getModel(
                client.apis.source_tracker.scrapeEbookJapan,
                {
                  series_id_range_start: parseInt(
                    this.state.series_id_range_start
                  ),
                  series_id_range_end: parseInt(this.state.series_id_range_end)
                },
                "EBookJapan"
              );
              break;
            case "book.dmm.com":
              results = await getModel(
                client.apis.source_tracker.scrapeDMM,
                {
                  series_id_range_start: parseInt(
                    this.state.series_id_range_start
                  ),
                  series_id_range_end: parseInt(this.state.series_id_range_end)
                },
                "DMM"
              );
              break;
            default:
              break;
          }
        } catch {
          // Do nothing
        }

        this.setState(
          {
            disabled: false
          },
          () => {
            if (results !== null) {
              switch (data.domain) {
                case "bookwalker.jp":
                  onUpdate("bookwalker", results);
                  break;
                case "ebookjapan.yahoo.co.jp":
                  onUpdate("ebookjapan", results);
                  break;
                case "book.dmm.com":
                  onUpdate("dmm", results);
                  break;
                default:
                  break;
              }
            }
          }
        );
      }
    );
  };

  render() {
    const { data } = this.props;
    if (data !== null) {
      return (
        <Table.Row>
          <Table.Cell>{getFromDatum(data, "domain")}</Table.Cell>
          <Table.Cell>
            {getFromDatum(data.datum, "ranges", [])
              .map(r => {
                return `[${r[0]}-${r[1]})`;
              })
              .join(", ")}
          </Table.Cell>
          <Table.Cell width={4}>
            <Form size="tiny">
              <Form.Group widths="equal">
                <Form.Field
                  name="series_id_range_start"
                  control={Input}
                  value={this.state.series_id_range_start}
                  onChange={this.onChange}
                  placeholder={"Start"}
                  type="number"
                  error={
                    this.state.series_id_range_start !== "" &&
                    this.state.series_id_range_end !== "" &&
                    parseInt(this.state.series_id_range_start) >=
                      parseInt(this.state.series_id_range_end)
                  }
                  fluid
                />

                <Form.Field
                  name="series_id_range_end"
                  control={Input}
                  value={this.state.series_id_range_end}
                  onChange={this.onChange}
                  placeholder={"End"}
                  type="number"
                  error={
                    this.state.series_id_range_start !== "" &&
                    this.state.series_id_range_end !== "" &&
                    parseInt(this.state.series_id_range_start) >=
                      parseInt(this.state.series_id_range_end)
                  }
                  fluid
                />

                <Form.Field
                  control={Button}
                  onClick={this.onSubmit}
                  disabled={
                    this.state.disabled ||
                    !(
                      this.state.series_id_range_start !== "" &&
                      this.state.series_id_range_end !== "" &&
                      parseInt(this.state.series_id_range_start) <
                        parseInt(this.state.series_id_range_end)
                    )
                  }
                  size="tiny"
                >
                  Request
                </Form.Field>
              </Form.Group>
            </Form>
          </Table.Cell>
        </Table.Row>
      );
    }
    return null;
  }
}

export default class SourceTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookwalker: null,
      ebookjapan: null,
      dmm: null
    };
  }

  onUpdate = (name, data) => {
    this.setState({
      [name]: data
    });
  };

  async componentDidMount() {
    const bookwalker = await getModel(
        client.apis.source_tracker.getBookWalker,
        {},
        "BookWalker"
      ),
      ebookjapan = await getModel(
        client.apis.source_tracker.getEbookJapan,
        {},
        "EbookJapan"
      ),
      dmm = await getModel(client.apis.source_tracker.getDMM, {}, "DMM");
    this.setState({
      bookwalker: bookwalker,
      ebookjapan: ebookjapan,
      dmm: dmm
    });
  }

  render() {
    return (
      <Container>
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header size="large">Source Tracker</Header>

              <Table fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>Domain</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Range</Table.HeaderCell>
                    <Table.HeaderCell width={8}>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Scraper
                    data={this.state.bookwalker}
                    onUpdate={this.onUpdate}
                  />

                  <Scraper
                    data={this.state.ebookjapan}
                    onUpdate={this.onUpdate}
                  />

                  <Scraper data={this.state.dmm} onUpdate={this.onUpdate} />
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
