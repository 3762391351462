import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  Header,
  List,
  Loader,
  Pagination,
  Table
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { client } from "../../../index";
import _ from "lodash";
import { getModel } from "../../../util/DataUtil";

const DEFAULT_PAGE_SIZE = 20;

export default class AuditPublisherCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceClusters: [],
      sourceClusterCount: -1,
      selectedSourceClusters: new Set(),
      pageCurrent: 1,
      pageTotal: 1
    };
  }

  toggleIncludeSourceCluster = id => {
    this.setState(prevState => ({
      selectedSourceClusters: prevState.selectedSourceClusters.has(id)
        ? new Set([...prevState.selectedSourceClusters].filter(i => i !== id))
        : new Set([...prevState.selectedSourceClusters, id])
    }));
  };

  toggleIncludeAll = () => {
    let isAll =
        this.state.selectedSourceClusters.size ===
        this.state.sourceClusters.length,
      selectedSourceClusters = new Set([...this.state.selectedSourceClusters]);

    if (isAll) {
      selectedSourceClusters = new Set();
    } else {
      _.map(this.state.sourceClusters, ({ id }) => {
        selectedSourceClusters.add(id);
      });
    }

    this.setState({
      selectedSourceClusters: selectedSourceClusters
    });
  };

  convertSourceCluster = async () => {
    await Promise.all(
      Array.from(this.state.selectedSourceClusters).map(async i => {
        return await client.apis.source_cluster.convertSourceClusterById({
          id: i,
          payload: {}
        });
      })
    );
    this.getPageInitial();
  };

  getPageNext = (e, pageInfo) => {
    this.setState({ isLoading: true }, async () => {
      const data = await getModel(
        client.apis.source_cluster.getSourceClusters,
        _.pickBy(
          {
            limit: DEFAULT_PAGE_SIZE,
            offset: (pageInfo.activePage - 1) * DEFAULT_PAGE_SIZE,
            source_cluster_type: "publisher_company"
          },
          _.identity
        ),
        "source cluster"
      );
      this.setState({
        sourceClusters: data.results,
        selectedSourceClusters: new Set(),
        pageCurrent: pageInfo.activePage,
        isLoading: false
      });
    });
  };

  getPageInitial = () => {
    this.setState({ isLoading: true }, async () => {
      const data = await getModel(
        client.apis.source_cluster.getSourceClusters,
        _.pickBy(
          {
            limit: DEFAULT_PAGE_SIZE,
            source_cluster_type: "publisher_company"
          },
          _.identity
        ),
        "source cluster"
      );
      this.setState({
        sourceClusters: data.results,
        sourceClusterCount: data.total,
        selectedSourceClusters: new Set(),
        pageCurrent: 1,
        pageTotal: Math.ceil(data.total / DEFAULT_PAGE_SIZE),
        isLoading: false
      });
    });
  };

  componentDidMount() {
    this.getPageInitial();
  }

  render() {
    if (this.state.isLoading && this.state.sourceClusterCount < 0) {
      return (
        <Container fluid>
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={10} style={{ minHeight: 100 }}>
                <Loader active>Loading...</Loader>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    return (
      <Container fluid>
        <Divider hidden />

        <Grid padded="horizontally">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header size="large">Audit Publisher Company</Header>
            </Grid.Column>
          </Grid.Row>

          {this.state.sourceClusterCount > 0 ? (
            <React.Fragment>
              <Grid.Row centered>
                <p>
                  Displaying{" "}
                  {(this.state.pageCurrent - 1) * DEFAULT_PAGE_SIZE + 1} -{" "}
                  {Math.min(
                    this.state.pageCurrent * DEFAULT_PAGE_SIZE,
                    this.state.sourceClusterCount
                  )}{" "}
                  out of {this.state.sourceClusterCount} results
                </p>
              </Grid.Row>

              <Grid.Row centered>
                <Pagination
                  activePage={this.state.pageCurrent}
                  totalPages={this.state.pageTotal}
                  onPageChange={this.getPageNext}
                  disabled={this.state.isLoading}
                />
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column width={12}>
                  <List horizontal>
                    <List.Item>
                      <Button
                        content={"Convert"}
                        onClick={this.convertSourceCluster}
                        color={"green"}
                        disabled={this.state.selectedSourceClusters.size <= 0}
                      />
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column width={12}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>
                          <Checkbox
                            checked={
                              this.state.selectedSourceClusters.size ===
                              this.state.sourceClusters.length
                            }
                            onChange={this.toggleIncludeAll}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>Id</Table.HeaderCell>
                        <Table.HeaderCell width={5}>Clusters</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {_.map(
                        this.state.sourceClusters,
                        ({
                          id,
                          cluster_type,
                          author_scrapes,
                          publisher_company_scrapes,
                          publisher_label_scrapes,
                          authors,
                          publisher_companies,
                          publisher_labels
                        }) => (
                          <Table.Row key={id}>
                            <Table.Cell>
                              <Checkbox
                                checked={this.state.selectedSourceClusters.has(
                                  id
                                )}
                                onChange={() => {
                                  this.toggleIncludeSourceCluster(id);
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>{id}</Table.Cell>
                            <Table.Cell>
                              <List>
                                {publisher_companies.map(m => {
                                  return (
                                    <List.Item key={`pc${m.id}`}>
                                      {m.name}
                                    </List.Item>
                                  );
                                })}

                                {publisher_company_scrapes.map(m => {
                                  return (
                                    <List.Item key={`spc${m.id}`}>
                                      <NavLink
                                        exact
                                        to={`/source_scrape/publisher_company_scrape/${m.id}`}
                                      >
                                        {m.name}
                                      </NavLink>
                                    </List.Item>
                                  );
                                })}
                              </List>
                            </Table.Cell>
                            <Table.Cell>
                              <List>
                                {publisher_companies.map(m => {
                                  return (
                                    <List.Item key={`pc${m.id}`}>
                                      {m.source}
                                    </List.Item>
                                  );
                                })}

                                {publisher_company_scrapes.map(m => {
                                  return (
                                    <List.Item key={`spc${m.id}`}>
                                      <a href={m.source}>{m.source}</a>
                                    </List.Item>
                                  );
                                })}
                              </List>
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          ) : (
            <Grid.Row>
              <Grid.Column>
                <p>No results were found</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }
}
