import React, { Component } from "react";
import { Button, Dropdown, Header, Input, Menu } from "semantic-ui-react";
import _, { debounce } from "lodash";
import { SERIES_TYPE_OPTIONS } from "../ModelEnum";
import { getModel } from "../../../util/DataUtil";
import { client } from "../../../index";

export default class SearchSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: props.params.limit || 20,
      series_name: props.params.series_name || "",
      series_type: props.params.series_type || "",
      tag_id: props.params.tag_id || [],
      tagOptions: [],
      tagIsFetching: false
    };
  }

  onChange = (e, { value }) => {
    let key = e.target.name;
    // Edge case for Select elements
    if (key === undefined || key === null) {
      key = e.target.parentElement.getAttribute("name");
    }
    if (key === undefined || key === null) {
      key = e.target.parentElement.parentElement.getAttribute("name");
    }
    if (key === undefined || key === null) {
      key = e.target.parentElement.parentElement.parentElement.getAttribute(
        "name"
      );
    }
    this.setState({
      [key]: value
    });
  };

  onSelectChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  onSearchChange = (e, { searchQuery }) => {
    this.setState(
      {
        tagIsFetching: true
      },
      async () => {
        try {
          const data = await getModel(
              client.apis.tag.getTag,
              { tag_name: searchQuery, tag_type: "tag", limit: 20 },
              "tag"
            ),
            dataTagOptions = data.results.map(t => {
              return { key: t.id, value: t.id, text: t.name };
            });

          let tagOptions = _.uniqBy(
            _.flatten([
              dataTagOptions,
              this.state.tagOptions.filter(t => {
                return _.indexOf(this.state.tag_id, t.value) > -1;
              })
            ]),
            "key"
          );

          this.setState({
            tagIsFetching: false,
            tagOptions: tagOptions
          });
        } catch {
          this.setState({
            tagIsFetching: false
          });
        }
      }
    );
  };

  onSubmit = () => {
    this.props.update(this.state);
  };

  updateTags = () => {
    this.setState({ tagIsFetching: true }, async () => {
      let tagOptions = [];
      if (this.state.tag_id.length > 0) {
        const data = await getModel(
          client.apis.tag.getTag,
          { tag_id: this.state.tag_id },
          "tag"
        );
        tagOptions = data.results.map(t => {
          return { key: t.id, value: t.id, text: t.name };
        });
      }

      this.setState({
        tagIsFetching: false,
        tagOptions: tagOptions
      });
    });
  };

  componentDidMount() {
    this.updateTags();
  }

  render() {
    return (
      <Menu vertical fluid>
        <Menu.Item>
          <Header size="large">Series</Header>
        </Menu.Item>

        <Menu.Item>
          <Input
            name="series_name"
            placeholder={"Search name"}
            value={this.state.series_name}
            onChange={this.onChange}
          />
        </Menu.Item>

        <Menu.Item>
          <Dropdown
            name="series_type"
            placeholder={"Search type"}
            value={this.state.series_type}
            options={SERIES_TYPE_OPTIONS}
            fluid
            selection
            clearable
            onChange={this.onChange}
          />
        </Menu.Item>

        <Menu.Item>
          <Dropdown
            placeholder={"Search tags"}
            value={this.state.tag_id}
            options={this.state.tagOptions}
            fluid
            multiple
            selection
            clearable
            search
            onChange={(e, { value }) => {
              this.onSelectChange("tag_id", value);
            }}
            onSearchChange={debounce(this.onSearchChange, 200)}
            disabled={this.state.tagIsFetching}
            loading={this.state.tagIsFetching}
          />
        </Menu.Item>

        <Menu.Item>
          <Button onClick={this.onSubmit} color="green">
            Submit
          </Button>
        </Menu.Item>
      </Menu>
    );
  }
}
