import React, { Component } from "react";
import {
  Breadcrumb,
  Container,
  Divider,
  Grid,
  Header,
  Pagination
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { client } from "../../../index";
import _ from "lodash";
import { getModel } from "../../../util/DataUtil";
import SeriesTable from "../../../component/model/table/SeriesTable";

const DEFAULT_PAGE_SIZE = 10;

export default class AuthorSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      author: null,
      series: [],
      seriesCount: 0,
      pageCurrent: 1,
      pageTotal: 1,
      isLoading: true
    };
  }

  getPageNext = (e, pageInfo) => {
    this.setState({ isLoading: true }, async () => {
      const data = await getModel(
        client.apis.series.getSeries,
        {
          limit: DEFAULT_PAGE_SIZE,
          offset: (pageInfo.activePage - 1) * DEFAULT_PAGE_SIZE,
          author_id: [this.props.match.params.id]
        },
        "series"
      );
      this.setState({
        series: data.results,
        pageCurrent: pageInfo.activePage,
        isLoading: false
      });
    });
  };

  getPageInitial = () => {
    this.setState({ isLoading: true }, async () => {
      const data = await getModel(
        client.apis.series.getSeries,
        { limit: DEFAULT_PAGE_SIZE, author_id: [this.props.match.params.id] },
        "series"
      );
      this.setState({
        series: data.results,
        seriesCount: data.total,
        pageCurrent: 1,
        pageTotal: Math.ceil(data.total / DEFAULT_PAGE_SIZE),
        isLoading: false
      });
    });
  };

  getModel = () => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        const data = await getModel(
          client.apis.author.getAuthorById,
          { id: this.props.match.params.id },
          "author"
        );
        this.setState(
          {
            author: data
          },
          async () => {
            await this.getPageInitial();
          }
        );
      }
    );
  };

  componentDidMount() {
    this.getModel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getModel();
    }
  }

  render() {
    if (this.state.isLoading && _.isNil(this.state.author)) {
      return null;
    }
    return (
      <Container>
        <Divider hidden />

        <Grid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={16}>
              <Breadcrumb size="massive">
                <Breadcrumb.Section>
                  <NavLink exact to="/author">
                    Authors
                  </NavLink>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section active>
                  {this.state.author.name}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row verticalAlign="middle">
            <Grid.Column width={8}>
              <Header size="large">Series By Author</Header>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              {this.state.seriesCount > 0 ? (
                <p>
                  Displaying{" "}
                  {(this.state.pageCurrent - 1) * DEFAULT_PAGE_SIZE + 1} -{" "}
                  {Math.min(
                    this.state.pageCurrent * DEFAULT_PAGE_SIZE,
                    this.state.seriesCount
                  )}{" "}
                  out of {this.state.seriesCount} results
                </p>
              ) : null}
            </Grid.Column>
          </Grid.Row>

          {this.state.seriesCount > 0 ? (
            <React.Fragment>
              <Grid.Row centered>
                <Pagination
                  activePage={this.state.pageCurrent}
                  totalPages={this.state.pageTotal}
                  onPageChange={this.getPageNext}
                />
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column width={16}>
                  <SeriesTable series={this.state.series} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Pagination
                  activePage={this.state.pageCurrent}
                  totalPages={this.state.pageTotal}
                  onPageChange={this.getPageNext}
                />
              </Grid.Row>
            </React.Fragment>
          ) : (
            <Grid.Row>
              <Grid.Column>
                <p>No results were found</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }
}
