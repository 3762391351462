import React, { Component } from "react";
import { Label, Table } from "semantic-ui-react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { SERIES_TYPE_ENUM_TO_TEXT } from "../ModelEnum";
import { getFromDatum } from "../../../util/DataUtil";
import AuthorLabel from "../datum/AuthorLabel";
import TagLabel from "../datum/TagLabel";
import SourceLabel from "../datum/SourceLabel";
import PublisherCompanyLabel from "../datum/PublisherCompanyLabel";
import PublisherLabelLabel from "../datum/PublisherLabelLabel";

export default class SeriesTable extends Component {
  render() {
    const { series } = this.props;

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Title</Table.HeaderCell>
            <Table.HeaderCell width={2}>Sources</Table.HeaderCell>
            <Table.HeaderCell width={1}>Type</Table.HeaderCell>
            <Table.HeaderCell width={2}>Original Run Date</Table.HeaderCell>
            <Table.HeaderCell width={2}>Author</Table.HeaderCell>
            <Table.HeaderCell width={4}>Tags</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(
            series,
            ({
              id,
              name,
              series_type,
              datum,
              sources,
              authors,
              tags,
              publisher_companies,
              publisher_labels
            }) => (
              <Table.Row key={id}>
                <Table.Cell>
                  <NavLink exact to={`/series/${id}`}>
                    {name}
                  </NavLink>
                </Table.Cell>

                <Table.Cell>
                  <Label.Group>
                    {sources.map(s => {
                      return <SourceLabel key={s.id} source={s} />;
                    })}
                  </Label.Group>
                </Table.Cell>

                <Table.Cell>{SERIES_TYPE_ENUM_TO_TEXT[series_type]}</Table.Cell>

                <Table.Cell>
                  {getFromDatum(datum, "start_date")}-
                  {getFromDatum(datum, "end_date")}
                </Table.Cell>

                <Table.Cell>
                  <Label.Group>
                    {authors.map(a => {
                      return <AuthorLabel key={a.id} author={a} />;
                    })}
                  </Label.Group>
                </Table.Cell>

                <Table.Cell>
                  <Label.Group>
                    {_.sortBy(tags, "tag_type")
                      .reverse()
                      .map(t => {
                        return <TagLabel key={t.id} tag={t} />;
                      })}

                    {publisher_companies.map(p => {
                      return (
                        <PublisherCompanyLabel
                          key={p.id}
                          publisherCompany={p}
                        />
                      );
                    })}
                    {publisher_labels.map(p => {
                      return (
                        <PublisherLabelLabel key={p.id} publisherLabel={p} />
                      );
                    })}
                  </Label.Group>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    );
  }
}
