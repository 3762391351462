import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { getFromDatum } from "../../../util/DataUtil";

export default class PublisherLabelLabel extends Component {
  render() {
    const { publisherLabel } = this.props;

    return (
      <Label
        color={"red"}
        as={NavLink}
        exact
        to={`/publisher/label/${publisherLabel.id}`}
      >
        {getFromDatum(publisherLabel, "name")}
      </Label>
    );
  }
}
