import { checkClientResponse } from "./DataUtil";
import store from "store";
import { client } from "../index";

function setClientAuth(token) {
  client.authorizations = { token: { value: token } };
}

export async function login(username, password) {
  const resp = await client.apis.auth.loginUser(null, {
      securities: {
        authorized: { basic: { username: username, password: password } }
      }
    }),
    user = checkClientResponse(resp, "There was an issue logging in");
  store.set("user", user);
  setClientAuth(user.token);
}

export function logout() {
  client.authorizations = null;
  store.remove("user");
}

export function reload() {
  if (isLoggedIn()) {
    let user = store.get("user");
    setClientAuth(user.token);
  }
}

export function isLoggedIn() {
  let user = store.get("user");
  return user !== undefined && user.token !== undefined;
}
