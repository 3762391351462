import React, { Component } from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  Loader,
  Pagination
} from "semantic-ui-react";
import { client } from "../../../index";
import _ from "lodash";
import { getModel } from "../../../util/DataUtil";
import SourceScrapesTable from "../../../component/model/table/SourceScrapesTable";

const DEFAULT_PAGE_SIZE = 20;

export default class SourceScrapeByPublisherCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceScrapes: [],
      sourceScrapeCount: -1,
      selectedSourceScrapes: new Set(),
      pageCurrent: 1,
      pageTotal: 1
    };
  }

  getPageNext = (e, pageInfo) => {
    this.setState({ isLoading: true }, async () => {
      const data = await getModel(
        client.apis.source_scrape.getSourceScrapes,
        _.pickBy(
          {
            limit: DEFAULT_PAGE_SIZE,
            offset: (pageInfo.activePage - 1) * DEFAULT_PAGE_SIZE,
            publisher_company_id: [this.props.match.params.id]
          },
          _.identity
        ),
        "source scrape"
      );
      this.setState({
        sourceScrapes: data.results,
        selectedSourceScrapes: new Set(),
        pageCurrent: pageInfo.activePage,
        isLoading: false
      });
    });
  };

  getPageInitial = () => {
    this.setState({ isLoading: true }, async () => {
      const data = await getModel(
        client.apis.source_scrape.getSourceScrapes,
        _.pickBy(
          {
            limit: DEFAULT_PAGE_SIZE,
            publisher_company_id: [this.props.match.params.id]
          },
          _.identity
        ),
        "source scrape"
      );
      this.setState({
        sourceScrapes: data.results,
        sourceScrapeCount: data.total,
        selectedSourceScrapes: new Set(),
        pageCurrent: 1,
        pageTotal: Math.ceil(data.total / DEFAULT_PAGE_SIZE),
        isLoading: false
      });
    });
  };

  componentDidMount() {
    this.getPageInitial();
  }

  render() {
    if (this.state.isLoading && this.state.sourceScrapeCount < 0) {
      return (
        <Container fluid>
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={10} style={{ minHeight: 100 }}>
                <Loader active>Loading...</Loader>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    return (
      <Container fluid>
        <Divider hidden />

        <Grid padded="horizontally">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header size="large">Scrapes</Header>
            </Grid.Column>
          </Grid.Row>

          {this.state.sourceScrapeCount > 0 ? (
            <React.Fragment>
              <Grid.Row centered>
                <p>
                  Displaying{" "}
                  {(this.state.pageCurrent - 1) * DEFAULT_PAGE_SIZE + 1} -{" "}
                  {Math.min(
                    this.state.pageCurrent * DEFAULT_PAGE_SIZE,
                    this.state.sourceScrapeCount
                  )}{" "}
                  out of {this.state.sourceScrapeCount} results
                </p>
              </Grid.Row>

              <Grid.Row centered>
                <Pagination
                  activePage={this.state.pageCurrent}
                  totalPages={this.state.pageTotal}
                  onPageChange={this.getPageNext}
                  disabled={this.state.isLoading}
                />
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column width={12}>
                  <SourceScrapesTable
                    sourceScrapes={this.state.sourceScrapes}
                  />
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          ) : (
            <Grid.Row>
              <Grid.Column>
                <p>No results were found</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }
}
