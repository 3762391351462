import React, { Component } from "react";
import { List, Image, Table } from "semantic-ui-react";
import _ from "lodash";

export default class SourceScrapesTable extends Component {
  render() {
    const { sourceScrapes } = this.props;

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>Title</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(
            sourceScrapes,
            ({ id, title, preview_img, uri, auto_process_datum }) => (
              <Table.Row key={id}>
                <Table.Cell>{id}</Table.Cell>

                <Table.Cell>
                  <Image src={preview_img} size={"tiny"} />
                </Table.Cell>

                <Table.Cell>
                  <List>
                    <List.Item>{title}</List.Item>
                    <List.Item>
                      <a href={uri}>{uri}</a>
                    </List.Item>
                  </List>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    );
  }
}
