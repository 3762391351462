import React, { Component } from "react";
import {
  Container,
  Divider,
  Grid,
  Loader,
  Pagination
} from "semantic-ui-react";
import { client } from "../../../index";
import _ from "lodash";
import {
  generateQueryString,
  getModel,
  parseQueryString
} from "../../../util/DataUtil";
import SearchSeries from "../../../component/model/menu/SearchSeries";
import SeriesTable from "../../../component/model/table/SeriesTable";

export default class Series extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      seriesCount: -1,
      pageCurrent: 1,
      pageTotal: 1,
      isLoading: false,
      searchParams: {}
    };
  }

  getPageNext = (e, pageInfo) => {
    let searchParams = _.assign({}, this.state.searchParams, {
      offset: (pageInfo.activePage - 1) * this.state.searchParams.limit
    });
    this.updateQueryParams(searchParams);
  };

  getPageInitial = () => {
    this.updateQueryParams(this.state.searchParams);
  };

  loadQueryParams = () => {
    const queryParams = parseQueryString(this.props.history.location.search);
    let searchParams = _.assign(
      { limit: 20, series_name: "", series_type: "", tag_id: [] },
      queryParams
    );
    if (!_.isNil(searchParams.tag_id) && !_.isArray(searchParams.tag_id)) {
      searchParams.tag_id = [parseInt(searchParams.tag_id)];
    } else if (_.isArray(searchParams.tag_id)) {
      searchParams.tag_id = searchParams.tag_id.map(t => {
        return parseInt(t);
      });
    }
    this.setState({ searchParams: searchParams }, async () => {
      this.getPageInitial();
    });
  };

  updateQueryParams = searchParams => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: generateQueryString(
        _.pickBy(
          {
            limit: searchParams.limit,
            series_name: searchParams.series_name,
            series_type: searchParams.series_type,
            tag_id: searchParams.tag_id,
            offset: searchParams.offset
          },
          _.identity
        )
      )
    });
    if (!this.state.isLoading) {
      this.setState(
        { isLoading: true, searchParams: searchParams },
        async () => {
          const data = await getModel(
            client.apis.series.getSeries,
            searchParams,
            "series"
          );
          this.setState({
            series: data.results,
            seriesCount: data.total,
            pageCurrent:
              Math.floor((searchParams.offset || 0) / searchParams.limit) + 1,
            pageTotal: Math.ceil(data.total / searchParams.limit),
            isLoading: false
          });
        }
      );
    }
  };

  componentDidMount() {
    this.loadQueryParams();
  }

  render() {
    if (this.state.seriesCount < 0) {
      return (
        <Container fluid>
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={10} style={{ minHeight: 100 }}>
                <Loader active>Loading...</Loader>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    return (
      <Container fluid>
        <Divider hidden />

        <Grid padded="horizontally">
          <Grid.Row centered>
            <Grid.Column width={4}>
              <SearchSeries
                params={this.state.searchParams}
                update={params => {
                  this.setState({ searchParams: params }, async () => {
                    await this.getPageInitial();
                  });
                }}
              />
            </Grid.Column>

            <Grid.Column width={10}>
              <Divider hidden />
              <Grid>
                {this.state.seriesCount > 0 ? (
                  <React.Fragment>
                    <Grid.Row centered>
                      <p>
                        Displaying{" "}
                        {(this.state.pageCurrent - 1) *
                          this.state.searchParams.limit +
                          1}{" "}
                        -{" "}
                        {Math.min(
                          this.state.pageCurrent *
                            this.state.searchParams.limit,
                          this.state.seriesCount
                        )}{" "}
                        out of {this.state.seriesCount} results
                      </p>
                    </Grid.Row>

                    <Grid.Row centered>
                      <Pagination
                        activePage={this.state.pageCurrent}
                        totalPages={this.state.pageTotal}
                        onPageChange={this.getPageNext}
                        disabled={this.state.isLoading}
                      />
                    </Grid.Row>

                    <Grid.Row centered>
                      <Grid.Column width={16}>
                        <SeriesTable series={this.state.series} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row centered>
                      <Pagination
                        activePage={this.state.pageCurrent}
                        totalPages={this.state.pageTotal}
                        onPageChange={this.getPageNext}
                        disabled={this.state.isLoading}
                      />
                    </Grid.Row>
                  </React.Fragment>
                ) : (
                  <Grid.Row>
                    <Grid.Column>
                      <p>No results were found</p>
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
