import "./index.css";
import "fomantic-ui-css/semantic.css";

import React from "react";
import ReactDOM from "react-dom";
import SwaggerClient from "swagger-client";
import App from "./App";
import { reload } from "./util/AuthUtil";
import * as serviceWorker from "./serviceWorker";

export let client = null;

async function load() {
  client = await new SwaggerClient({
    url: `${process.env.REACT_APP_SWAGGER_ENDPOINT}`,
    v2OperationIdCompatibilityMode: true
  });
  reload();

  ReactDOM.render(<App />, document.getElementById("root"));
}

load();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
