import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Message
} from "semantic-ui-react";
import { login } from "../../util/AuthUtil";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      isFormDisabled: false,
      redirectToReferrer: false,
      username: "",
      password: ""
    };
  }

  onChange = (e, { value }) => {
    let key = e.target.name;
    // Edge case for Select elements
    if (key === undefined) {
      key = e.target.parentElement.parentElement.getAttribute("name");
    }
    this.setState({
      [key]: value
    });
  };

  onSubmit = async () => {
    this.setState({
      isFormDisabled: true
    });
    try {
      await login(this.state.username, this.state.password);
      this.setState({
        redirectToReferrer: true
      });
    } catch (e) {
      this.setState({
        isFormDisabled: false,
        error: true,
        errorMessage:
          e.response !== undefined && e.response !== null
            ? e.response.obj.message
            : "There was an issue logging in"
      });
    }
  };

  render() {
    const { from } = this.props.location.state || {
        from: { pathname: "/scrape/process" }
      },
      { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }
    return (
      <Container>
        <Divider hidden />

        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              {this.state.error ? (
                <Message negative>{this.state.errorMessage}</Message>
              ) : null}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns="equal">
            <Grid.Column>
              <Form>
                <Form.Input
                  name="username"
                  label="Email Address"
                  type="email"
                  value={this.state.username}
                  onChange={this.onChange}
                />

                <Form.Input
                  name="password"
                  label="Password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />

                <Form.Field
                  control={Button}
                  onClick={this.onSubmit}
                  disabled={this.props.isFormDisabled}
                >
                  Login
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
