import React, { Component } from "react";
import {
  Container,
  Divider,
  Grid,
  Loader,
  Pagination
} from "semantic-ui-react";
import { client } from "../../../index";
import _ from "lodash";
import {
  generateQueryString,
  getModel,
  parseQueryString
} from "../../../util/DataUtil";
import SearchTags from "../../../component/model/menu/SearchTags";
import TagsTable from "../../../component/model/table/TagsTable";

export default class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tagCount: -1,
      pageCurrent: 1,
      pageTotal: 1,
      isLoading: false,
      searchParams: {}
    };
  }

  getPageNext = (e, pageInfo) => {
    let searchParams = _.assign({}, this.state.searchParams, {
      offset: (pageInfo.activePage - 1) * this.state.searchParams.limit
    });
    this.updateQueryParams(searchParams);
  };

  getPageInitial = () => {
    this.updateQueryParams(this.state.searchParams);
  };

  loadQueryParams = () => {
    const queryParams = parseQueryString(this.props.history.location.search);
    let searchParams = _.assign(
      { limit: 20, tag_name: "", tag_type: "" },
      queryParams
    );
    this.setState({ searchParams: searchParams }, async () => {
      this.getPageInitial();
    });
  };

  updateQueryParams = searchParams => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: generateQueryString(
        _.pickBy(
          {
            limit: searchParams.limit,
            tag_name: searchParams.tag_name,
            tag_type: searchParams.tag_type,
            offset: searchParams.offset
          },
          _.identity
        )
      )
    });
    if (!this.state.isLoading) {
      this.setState(
        { isLoading: true, searchParams: searchParams },
        async () => {
          const data = await getModel(
            client.apis.tag.getTag,
            searchParams,
            "tags"
          );
          this.setState({
            tags: data.results,
            tagCount: data.total,
            pageCurrent:
              Math.floor((searchParams.offset || 0) / searchParams.limit) + 1,
            pageTotal: Math.ceil(data.total / this.state.searchParams.limit),
            isLoading: false
          });
        }
      );
    }
  };

  componentDidMount() {
    this.loadQueryParams();
  }

  render() {
    if (this.state.tagCount < 0) {
      return (
        <Container fluid>
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={10} style={{ minHeight: 100 }}>
                <Loader active>Loading...</Loader>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    return (
      <Container fluid>
        <Divider hidden />

        <Grid padded="horizontally">
          <Grid.Row centered>
            <Grid.Column width={4}>
              <SearchTags
                params={this.state.searchParams}
                update={params => {
                  this.setState({ searchParams: params }, async () => {
                    await this.getPageInitial();
                  });
                }}
              />
            </Grid.Column>

            <Grid.Column width={10}>
              <Divider hidden />

              <Grid>
                {this.state.tagCount > 0 ? (
                  <Grid.Row centered>
                    <p>
                      Displaying{" "}
                      {(this.state.pageCurrent - 1) *
                        this.state.searchParams.limit +
                        1}{" "}
                      -{" "}
                      {Math.min(
                        this.state.pageCurrent * this.state.searchParams.limit,
                        this.state.tagCount
                      )}{" "}
                      out of {this.state.tagCount} results
                    </p>
                  </Grid.Row>
                ) : null}

                <Grid.Row centered>
                  <Pagination
                    activePage={this.state.pageCurrent}
                    totalPages={this.state.pageTotal}
                    onPageChange={this.getPageNext}
                  />
                </Grid.Row>

                <Grid.Row centered>
                  <Grid.Column width={16}>
                    <TagsTable tags={this.state.tags} />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                  <Pagination
                    activePage={this.state.pageCurrent}
                    totalPages={this.state.pageTotal}
                    onPageChange={this.getPageNext}
                  />
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
