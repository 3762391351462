import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { TAG_TYPE_ENUM_TO_TEXT } from "../ModelEnum";

export default class TagsTable extends Component {
  render() {
    const { tags } = this.props;

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(tags, ({ id, name, tag_type }) => (
            <Table.Row key={id}>
              <Table.Cell>
                <NavLink exact to={`/tag/${id}`}>
                  {name}
                </NavLink>
              </Table.Cell>

              <Table.Cell>{TAG_TYPE_ENUM_TO_TEXT[tag_type]}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
