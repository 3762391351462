import React, { Component } from "react";

export default class Source extends Component {
  render() {
    const { source } = this.props;
    if (source.uri !== undefined && source.uri !== null) {
      return (
        <a href={source.uri}>
          {source.uri.includes("ebookjapan.yahoo.co.jp") ? (
            <img
              src="/static/sources/ebookjapan.yahoo.co.jp.png"
              style={{ width: 24, height: 24 }}
              alt=""
            />
          ) : source.uri.includes("bookwalker.jp") ? (
            <img
              src="/static/sources/bookwalker.jp.png"
              style={{ width: 24, height: 24 }}
              alt=""
            />
          ) : null}
        </a>
      );
    }
    return null;
  }
}
