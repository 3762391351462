import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { getFromDatumLocaleDict } from "../../../util/DataUtil";

export default class AuthorsTable extends Component {
  render() {
    const { authors } = this.props;

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Name</Table.HeaderCell>
            <Table.HeaderCell width={4}>Name (English)</Table.HeaderCell>
            <Table.HeaderCell width={4}>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(authors, ({ id, name, datum }) => (
            <Table.Row key={id}>
              <Table.Cell>
                <NavLink exact to={`/author/${id}`}>
                  {name}
                </NavLink>
              </Table.Cell>

              <Table.Cell>
                {getFromDatumLocaleDict(datum, "name", "en")}
              </Table.Cell>

              <Table.Cell>
                {getFromDatumLocaleDict(datum, "types", "ja", []).join(", ")}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
