import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";

export class Header extends Component {
  render() {
    return (
      <Menu pointing secondary>
        <NavLink exact className="item" to="/source_tracker">
          Source Track
        </NavLink>

        <NavLink exact className="item" to="/audit/publisher_company">
          Audit Publisher Company
        </NavLink>

        <NavLink exact className="item" to="/audit/publisher_label">
          Audit Publisher Label
        </NavLink>

        <NavLink exact className="item" to="/audit/author">
          Audit Author
        </NavLink>

        <NavLink exact className="item" to="/audit/series">
          Audit Series
        </NavLink>

        <NavLink className="item" to="/publisher_company">
          Publisher Company
        </NavLink>

        <NavLink className="item" to="/publisher_label">
          Publisher Label
        </NavLink>

        <NavLink className="item" to="/collection">
          Collections
        </NavLink>

        <NavLink className="item" to="/series">
          Series
        </NavLink>

        <NavLink className="item" to="/author">
          Authors
        </NavLink>

        <NavLink className="item" to="/tag">
          Tags
        </NavLink>

        <NavLink className="item" to="/tag_collection">
          Tag Collection
        </NavLink>
      </Menu>
    );
  }
}
