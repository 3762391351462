import React, { Component } from "react";
import { Flag, Image, Label, List, Table } from "semantic-ui-react";
import { getFromDatum, getFromDatumLocaleDict } from "../../util/DataUtil";
import Source from "./Source";

export class SeriesBooks extends Component {
  render() {
    const { books } = this.props;
    return (
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2} />
            <Table.HeaderCell>Sources</Table.HeaderCell>
            <Table.HeaderCell width={4}>Title (Romaji)</Table.HeaderCell>
            <Table.HeaderCell width={6}>Description</Table.HeaderCell>
            <Table.HeaderCell>Publish Date</Table.HeaderCell>
            <Table.HeaderCell>Digital Publish Date</Table.HeaderCell>
            <Table.HeaderCell>Page Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {books.map(b => {
            return (
              <Table.Row key={b.id}>
                <Table.Cell>
                  <Image src={getFromDatum(b.datum, "img_raw")} fluid />
                </Table.Cell>
                <Table.Cell>
                  <List horizontal>
                    {b.sources.map(s => {
                      return (
                        <List.Item key={s.id}>
                          <Source source={s} />
                        </List.Item>
                      );
                    })}
                  </List>
                </Table.Cell>
                <Table.Cell>
                  {getFromDatum(b, "title")}
                  <br />({getFromDatumLocaleDict(b.datum, "title", "ja_romaji")}
                  )
                </Table.Cell>
                <Table.Cell>{getFromDatum(b, "description")}</Table.Cell>
                <Table.Cell>{getFromDatum(b.datum, "publish_date")}</Table.Cell>
                <Table.Cell>{getFromDatum(b.datum, "digital_publish_date")}</Table.Cell>
                <Table.Cell>{getFromDatum(b.datum, "pages")}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

export default class SeriesModel extends Component {
  render() {
    const { series } = this.props;
    return (
      <Table definition fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4} />
            <Table.HeaderCell>{getFromDatum(series, "name")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>{getFromDatum(series, "name")}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Name [Japanese] (Romaji)</Table.Cell>
            <Table.Cell>
              {getFromDatumLocaleDict(series.datum, "name", "ja_romaji")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Name [English]</Table.Cell>
            <Table.Cell>
              {getFromDatumLocaleDict(series.datum, "name", "en")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Language</Table.Cell>
            <Table.Cell>
              <Flag name={getFromDatum(series, "language_iso")} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>{getFromDatum(series, "series_type")}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Start Date</Table.Cell>
            <Table.Cell>{getFromDatum(series.datum, "start_date")}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>End Date</Table.Cell>
            <Table.Cell>{getFromDatum(series.datum, "end_date")}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Authors</Table.Cell>
            <Table.Cell>
              <Label.Group>
                {series.authors.map(a => {
                  return (
                    <Label key={a.id} color={"green"}>
                      {a.name} ({getFromDatum(a.datum, "type_ja")})
                    </Label>
                  );
                })}
              </Label.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Tags</Table.Cell>
            <Table.Cell>
              <Label.Group>
                {series.tags.map(t => {
                  return (
                    <Label
                      key={t.id}
                      color={
                        t.tag_type === "tag"
                          ? "grey"
                          : t.tag_type === "publisher_label"
                          ? "red"
                          : t.tag_type === "publisher_company"
                          ? "orange"
                          : "black"
                      }
                    >
                      {t.name}
                    </Label>
                  );
                })}
              </Label.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Books</Table.Cell>
            <Table.Cell>
              <List>
                {series.books.map(b => {
                  return <List.Item key={b.id}>{b.title}</List.Item>;
                })}
              </List>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Sources</Table.Cell>
            <Table.Cell>
              <List horizontal>
                {series.sources.map(s => {
                  return (
                    <List.Item key={s.id}>
                      <Source source={s} />
                    </List.Item>
                  );
                })}
              </List>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
