function getKeyToTextMap(d) {
  let m = {};
  d.forEach(i => {
    m[i.key] = i.text;
  });
  return m;
}

export const COUNTRY_OPTIONS = [
  { key: "jp", value: "jp", flag: "jp", text: "Japan" }
];

export const SCRAPE_DOMAIN_OPTIONS = [
  { key: "all", value: "all", text: "All" },
  { key: "bookwalker.jp", value: "bookwalker.jp", text: "bookwalker.jp" },
  {
    key: "ebookjapan.yahoo.co.jp",
    value: "ebookjapan.yahoo.co.jp",
    text: "ebookjapan.yahoo.co.jp"
  },
  {
    key: "book.dmm.com",
    value: "book.dmm.com",
    text: "book.dmm.com"
  }
];

export const SERIES_TYPE_OPTIONS = [
  { key: "unknown", value: "unknown", text: "Unknown" },
  { key: "manga", value: "manga", text: "Manga" },
  { key: "light_novel", value: "light_novel", text: "Light Novel" },
  { key: "novel", value: "novel", text: "Novel" }
];

export const TAG_TYPE_OPTIONS = [
  { key: "tag", value: "tag", text: "Tag" },
  { key: "system", value: "system", text: "System" }
];

export const SOURCE_SCRAPE_STATUS_OPTIONS = [
  { key: "unknown", value: "unknown", text: "Unknown" },
  { key: "in_scrape_queue", value: "in_scrape_queue", text: "Scraping" },
  {
    key: "in_process_queue",
    value: "in_process_queue",
    text: "Audit Required"
  },
  { key: "is_completed", value: "is_completed", text: "Completed" },
  { key: "is_ignored", value: "is_ignored", text: "Ignored" },
  { key: "is_nonexistent", value: "is_nonexistent", text: "Does Not Exist" },
  { key: "is_errored", value: "is_errored", text: "Error" }
];

export const SERIES_TYPE_ENUM_TO_TEXT = getKeyToTextMap(SERIES_TYPE_OPTIONS);

export const TAG_TYPE_ENUM_TO_TEXT = getKeyToTextMap(TAG_TYPE_OPTIONS);

export const SOURCE_SCRAPE_STATUS_TO_TEXT = getKeyToTextMap(
  SOURCE_SCRAPE_STATUS_OPTIONS
);
