import React, { Component } from "react";
import { Label } from "semantic-ui-react";

function getIcon(source) {
  if (source.uri.includes("ebookjapan.yahoo.co.jp")) {
    return "/static/sources/ebookjapan.yahoo.co.jp.png";
  }
  if (source.uri.includes("bookwalker.jp")) {
    return "/static/sources/bookwalker.jp.png";
  }
}

function getIconLabel(source) {
  if (source.uri.includes("ebookjapan.yahoo.co.jp")) {
    return "EbookJapan";
  }
  if (source.uri.includes("bookwalker.jp")) {
    return "BookWalker";
  }
}

export default class SourceLabel extends Component {
  render() {
    const { source } = this.props;

    return (
      <Label image as="a" href={source.uri}>
        <img src={getIcon(source)} alt="" />
        {getIconLabel(source)}
      </Label>
    );
  }
}
