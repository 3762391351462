import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { getFromDatum, getFromDatumLocaleDict } from "../../../util/DataUtil";

export default class AuthorLabel extends Component {
  render() {
    const { author } = this.props;

    let authorTypesAsString = getFromDatumLocaleDict(
      author.datum,
      "types",
      "ja",
      []
    ).join(", ");

    return (
      <Label color={"green"} as={NavLink} exact to={`/author/${author.id}`}>
        {getFromDatum(author, "name")}
        {authorTypesAsString !== "" ? ` (${authorTypesAsString})` : ""}
      </Label>
    );
  }
}
