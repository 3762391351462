import React, { Component } from "react";
import { Flag, Table } from "semantic-ui-react";
import { getFromDatum, getFromDatumLocaleDict } from "../../util/DataUtil";

export default class CollectionModel extends Component {
  render() {
    const { collection } = this.props;
    return (
      <Table definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>{getFromDatum(collection, "name")}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Name [Japanese] (Romaji)</Table.Cell>
            <Table.Cell>
              {getFromDatumLocaleDict(collection.datum, "name", "ja_romaji")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Name [English]</Table.Cell>
            <Table.Cell>
              {getFromDatumLocaleDict(collection.datum, "name", "en")}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Language</Table.Cell>
            <Table.Cell>
              <Flag name={getFromDatum(collection, "language_iso")} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
